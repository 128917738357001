
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace('')
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91_46_46_46slug_93VPlFF0fkCFMeta } from "/builds/platform/customer-frontends/tigagame/skeleton/pages/[...slug].vue?macro=true";
import { default as autologinhqAgQFoNVGMeta } from "/builds/platform/customer-frontends/tigagame/skeleton/pages/auth/autologin.vue?macro=true";
import { default as callbackzi0bFtgnI9Meta } from "/builds/platform/customer-frontends/tigagame/skeleton/pages/auth/callback.vue?macro=true";
import { default as bettinguqVv1bSUwWMeta } from "/builds/platform/customer-frontends/tigagame/skeleton/pages/betting.vue?macro=true";
import { default as _91pageIdentity_93CYz11Cq2fvMeta } from "/builds/platform/customer-frontends/tigagame/skeleton/pages/bonus/[pageIdentity].vue?macro=true";
import { default as _91categoryIdentity_93nDS9wrctx9Meta } from "/builds/platform/customer-frontends/tigagame/skeleton/pages/categories/[categoryIdentity].vue?macro=true";
import { default as categoriesniMxLPt5lSMeta } from "/builds/platform/customer-frontends/tigagame/skeleton/pages/categories.vue?macro=true";
import { default as contactibanRJ0HpPMeta } from "/builds/platform/customer-frontends/tigagame/skeleton/pages/contact.vue?macro=true";
import { default as favorites_46clientfSVdHWaWANMeta } from "/builds/platform/customer-frontends/tigagame/skeleton/pages/favorites.client.vue?macro=true";

let _createClientPage
async function createClientPage(loader) {
  _createClientPage ||= await import("/builds/platform/customer-frontends/tigagame/node_modules/nuxt/dist/components/runtime/client-component.js").then(r => r.createClientPage)
  return _createClientPage(loader);
}
import { default as _91id_9369k7rNNWc2Meta } from "/builds/platform/customer-frontends/tigagame/skeleton/pages/games/[id].vue?macro=true";
import { default as indexPuGYvMxjbYMeta } from "/builds/platform/customer-frontends/tigagame/skeleton/pages/index.vue?macro=true";
import { default as loyaltyhfTtXv5NqHMeta } from "/builds/platform/customer-frontends/tigagame/skeleton/pages/loyalty.vue?macro=true";
import { default as maind2nrQRzTBwMeta } from "/builds/platform/customer-frontends/tigagame/skeleton/pages/main.vue?macro=true";
import { default as bonusesKXUUiflkbUMeta } from "/builds/platform/customer-frontends/tigagame/skeleton/pages/profile/bonuses.vue?macro=true";
import { default as documentsA8PrzYmCPdMeta } from "/builds/platform/customer-frontends/tigagame/skeleton/pages/profile/documents.vue?macro=true";
import { default as historycdQ5mdkbDXMeta } from "/builds/platform/customer-frontends/tigagame/skeleton/pages/profile/history.vue?macro=true";
import { default as infoD9N3VH1pj4Meta } from "/builds/platform/customer-frontends/tigagame/skeleton/pages/profile/info.vue?macro=true";
import { default as limitsPoWa0KwaDnMeta } from "/builds/platform/customer-frontends/tigagame/skeleton/pages/profile/limits.vue?macro=true";
import { default as notificationss8LtNOluTAMeta } from "/builds/platform/customer-frontends/tigagame/skeleton/pages/profile/notifications.vue?macro=true";
import { default as securityEMOvXvBn76Meta } from "/builds/platform/customer-frontends/tigagame/skeleton/pages/profile/security.vue?macro=true";
import { default as verificationp4w4G3ugPDMeta } from "/builds/platform/customer-frontends/tigagame/skeleton/pages/profile/verification.vue?macro=true";
import { default as walletbxzrG9j6z2Meta } from "/builds/platform/customer-frontends/tigagame/skeleton/pages/profile/wallet.vue?macro=true";
import { default as profile_46clientbilLZTCkVZMeta } from "/builds/platform/customer-frontends/tigagame/skeleton/pages/profile.client.vue?macro=true";
import { default as providersUqAJ4TmN2YMeta } from "/builds/platform/customer-frontends/tigagame/skeleton/pages/providers.vue?macro=true";
import { default as _91pageIdentity_9309Nmg7Bxg4Meta } from "/builds/platform/customer-frontends/tigagame/skeleton/pages/questions/[pageIdentity].vue?macro=true";
import { default as questionsAEsI7w5rV2Meta } from "/builds/platform/customer-frontends/tigagame/skeleton/pages/questions.vue?macro=true";
import { default as recently_45played_46clientY9M15myyGWMeta } from "/builds/platform/customer-frontends/tigagame/skeleton/pages/recently-played.client.vue?macro=true";
import { default as _91pageIdentity_933sFX53ucz6Meta } from "/builds/platform/customer-frontends/tigagame/skeleton/pages/static/[pageIdentity].vue?macro=true";
import { default as _91identity_93moxGsxh8x6Meta } from "/builds/platform/customer-frontends/tigagame/skeleton/pages/tournaments/[identity].vue?macro=true";
import { default as index1AHWVRthshMeta } from "/builds/platform/customer-frontends/tigagame/skeleton/pages/tournaments/index.vue?macro=true";
import { default as welcome_45packageXLI0mJlf8EMeta } from "/builds/platform/customer-frontends/tigagame/skeleton/pages/welcome-package.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/builds/platform/customer-frontends/tigagame/skeleton/pages/[...slug].vue")
  },
  {
    name: "auth-autologin",
    path: "/auth/autologin",
    component: () => import("/builds/platform/customer-frontends/tigagame/skeleton/pages/auth/autologin.vue")
  },
  {
    name: "auth-callback",
    path: "/auth/callback",
    component: () => import("/builds/platform/customer-frontends/tigagame/skeleton/pages/auth/callback.vue")
  },
  {
    name: "betting",
    path: "/betting",
    component: () => import("/builds/platform/customer-frontends/tigagame/skeleton/pages/betting.vue")
  },
  {
    name: "bonus-pageIdentity",
    path: "/bonus/:pageIdentity()",
    component: () => import("/builds/platform/customer-frontends/tigagame/skeleton/pages/bonus/[pageIdentity].vue")
  },
  {
    name: "categories",
    path: "/categories",
    component: () => import("/builds/platform/customer-frontends/tigagame/skeleton/pages/categories.vue"),
    children: [
  {
    name: "categories-categoryIdentity",
    path: ":categoryIdentity()",
    component: () => import("/builds/platform/customer-frontends/tigagame/skeleton/pages/categories/[categoryIdentity].vue")
  }
]
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/builds/platform/customer-frontends/tigagame/skeleton/pages/contact.vue")
  },
  {
    name: "favorites",
    path: "/favorites",
    component: () => createClientPage(() => import("/builds/platform/customer-frontends/tigagame/skeleton/pages/favorites.client.vue"))
  },
  {
    name: "games-id",
    path: "/games/:id()",
    component: () => import("/builds/platform/customer-frontends/tigagame/skeleton/pages/games/[id].vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/builds/platform/customer-frontends/tigagame/skeleton/pages/index.vue")
  },
  {
    name: "loyalty",
    path: "/loyalty",
    component: () => import("/builds/platform/customer-frontends/tigagame/skeleton/pages/loyalty.vue")
  },
  {
    name: "main",
    path: "/main",
    component: () => import("/builds/platform/customer-frontends/tigagame/skeleton/pages/main.vue")
  },
  {
    name: "profile",
    path: "/profile",
    component: () => createClientPage(() => import("/builds/platform/customer-frontends/tigagame/skeleton/pages/profile.client.vue")),
    children: [
  {
    name: "profile-bonuses",
    path: "bonuses",
    component: () => import("/builds/platform/customer-frontends/tigagame/skeleton/pages/profile/bonuses.vue")
  },
  {
    name: "profile-documents",
    path: "documents",
    component: () => import("/builds/platform/customer-frontends/tigagame/skeleton/pages/profile/documents.vue")
  },
  {
    name: "profile-history",
    path: "history",
    component: () => import("/builds/platform/customer-frontends/tigagame/skeleton/pages/profile/history.vue")
  },
  {
    name: "profile-info",
    path: "info",
    component: () => import("/builds/platform/customer-frontends/tigagame/skeleton/pages/profile/info.vue")
  },
  {
    name: "profile-limits",
    path: "limits",
    component: () => import("/builds/platform/customer-frontends/tigagame/skeleton/pages/profile/limits.vue")
  },
  {
    name: "profile-notifications",
    path: "notifications",
    component: () => import("/builds/platform/customer-frontends/tigagame/skeleton/pages/profile/notifications.vue")
  },
  {
    name: "profile-security",
    path: "security",
    component: () => import("/builds/platform/customer-frontends/tigagame/skeleton/pages/profile/security.vue")
  },
  {
    name: "profile-verification",
    path: "verification",
    component: () => import("/builds/platform/customer-frontends/tigagame/skeleton/pages/profile/verification.vue")
  },
  {
    name: "profile-wallet",
    path: "wallet",
    component: () => import("/builds/platform/customer-frontends/tigagame/skeleton/pages/profile/wallet.vue")
  }
]
  },
  {
    name: "providers",
    path: "/providers",
    component: () => import("/builds/platform/customer-frontends/tigagame/skeleton/pages/providers.vue")
  },
  {
    name: "questions",
    path: "/questions",
    meta: questionsAEsI7w5rV2Meta || {},
    component: () => import("/builds/platform/customer-frontends/tigagame/skeleton/pages/questions.vue"),
    children: [
  {
    name: "questions-pageIdentity",
    path: ":pageIdentity()",
    component: () => import("/builds/platform/customer-frontends/tigagame/skeleton/pages/questions/[pageIdentity].vue")
  }
]
  },
  {
    name: "recently-played",
    path: "/recently-played",
    component: () => createClientPage(() => import("/builds/platform/customer-frontends/tigagame/skeleton/pages/recently-played.client.vue"))
  },
  {
    name: "static-pageIdentity",
    path: "/static/:pageIdentity()",
    component: () => import("/builds/platform/customer-frontends/tigagame/skeleton/pages/static/[pageIdentity].vue")
  },
  {
    name: "tournaments-identity",
    path: "/tournaments/:identity()",
    component: () => import("/builds/platform/customer-frontends/tigagame/skeleton/pages/tournaments/[identity].vue")
  },
  {
    name: "tournaments",
    path: "/tournaments",
    component: () => import("/builds/platform/customer-frontends/tigagame/skeleton/pages/tournaments/index.vue")
  },
  {
    name: "welcome-package",
    path: "/welcome-package",
    component: () => import("/builds/platform/customer-frontends/tigagame/skeleton/pages/welcome-package.vue")
  }
]